@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Source Sans Pro', sans-serif;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Signika', sans-serif;
    }
}

html {
    scroll-behavior: smooth;
}

/**{*/
/*    outline: 1px solid limegreen;*/
/*    outline-offset: 15px;*/
/*}*/

::-webkit-scrollbar{
    width: 12px;
}

::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius: 10px;
    border: 2px solid white;
    transition: all 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
::-webkit-scrollbar-thumb:hover{
    background-color: #218dc5;
    border-radius: 10px;
}

.react-horizontal-scrolling-menu--item {
    flex: 0 0 100%;
}

.react-horizontal-scrolling-menu--scroll-container {
    overflow-x: hidden;
}

.scroll-item-packed {
    flex: 0 0;
    margin: 0 10px;
}
